/*
   @description pathString 에 따라 json 객체 내의 key 를 쫒아가 value 를 반환하는 함수
   @param {object} obj - 쫒아갈 객체
   @param {string} pathString - 쫒아갈 화살표 경로 ex) 'a->b->c' or 'a->${key}->c'
   @param {object} [replacementObj={}] - ${} 표현식을 대체할 값들이 있는 객체
   @returns {any} 쫒아간 끝 value
*/
export function pathFinder(obj, pathString, replacementObj = {}) {
  if (!obj) return undefined;

  if (typeof obj !== 'object' || Array.isArray(obj)) {
    throw new Error('첫 번째 인자는 객체 타입이어야 합니다.');
  }

  if (!pathString) throw new Error('경로 문자열이 제공되지 않았습니다.');

  // ${} 표현식이 있는지 확인하고 있다면 makePathFinderString 호출
  if (pathString.includes('${')) {
    try {
      pathString = makePathFinderString(pathString, replacementObj);
    } catch (error) {
      throw new Error(`Path replacement failed: ${error.message}`);
    }
  }

  const paths = pathString.split('->');
  let current = obj;

  // 객체 조건 문자열을 파싱하는 함수 추가
  const parseCondition = (conditionStr) => {
    // 작은따옴표를 큰따옴표로 변환하고 공백 제거
    const cleanStr = conditionStr.trim().replace(/'/g, '"');
    try {
      // 속성 이름에도 큰따옴표 추가
      const jsonStr = cleanStr.replace(/([{,]\s*)(\w+):/g, '$1"$2":');
      return JSON.parse(jsonStr);
    } catch (e) {
      throw new Error(`Invalid condition format: ${conditionStr}`);
    }
  };

  for (const path of paths) {
    if (!current) return undefined;

    // 배열 접근 패턴 체크 (예: key[0] 또는 key[{id: 1}])
    const arrayMatch = path.match(/^(\w+)(\[.+\])+$/);
    if (arrayMatch) {
      const key = arrayMatch[1];
      const keyPart = path.slice(key.length);
      const arrayAccessors = keyPart.match(/\[([^\]]+)\]/g);

      current = current[key];
      if (!Array.isArray(current)) {
        throw new Error(`${key}는 배열이 아닙니다`);
      }

      for (const accessor of arrayAccessors) {
        const innerValue = accessor.slice(1, -1);

        // 객체 조건으로 검색하는 경우
        if (innerValue.startsWith('{')) {
          const conditions = innerValue.split('|').map((condition) => parseCondition(condition.trim()));

          current = current.find((item) =>
            conditions.some((condition) => Object.entries(condition).every(([k, v]) => item[k] === v)),
          );
        }
        // 숫자 인덱스로 접근하는 경우
        else {
          const index = parseInt(innerValue);
          if (index >= current.length) {
            throw new Error(`배열 인덱스 ${index}가 범위를 벗어났습니다`);
          }
          current = current[index];
        }
      }
      continue;
    }

    // optional chaining 체크 (예: a?)
    const optionalMatch = path.match(/^(\w+)\?$/);
    if (optionalMatch) {
      const key = optionalMatch[1];
      current = current[key];
      if (current === undefined) return undefined;
      continue;
    }

    // 일반 객체 접근
    if (current[path] === undefined && !path.endsWith('?')) {
      throw new Error(`${path} 키를 찾을 수 없습니다`);
    }
    current = current[path];
  }

  return current;
}

/**
 * pathString에서 동적 값을 대체하는 함수
 * @param {string} pathString - 경로 문자열 (예: "a->b[0]->${variable-name_123}->b")
 * @param {Object} replacements - 대체할 값들을 담은 객체
 * @returns {string} 대체된 경로 문자열
 * @throws {Error} pathString에 있는 모든 ${} 표현식의 키가 replacements에 없을 경우
 */
export function makePathFinderString(pathString, replacements) {
  // ${} 안의 모든 문자열을 찾음 (문자, 숫자, 특수문자, 하이픈, 언더바 포함)
  const variableKeys = pathString.match(/\$\{([a-zA-Z0-9\-_]+)\}/g) || [];

  // ${} 를 제거하고 실제 키 이름만 추출
  const requiredKeys = variableKeys.map((key) => key.slice(2, -1));

  // replacements 객체에 필요한 모든 키가 있는지 확인
  const missingKeys = requiredKeys.filter((key) => !(key in replacements));

  if (missingKeys.length > 0) {
    throw new Error(`다음 키들이 replacements 객체에 없습니다: ${missingKeys.join(', ')}`);
  }

  // 모든 ${key} 형식의 표현식을 해당하는 값으로 대체
  let resultPath = pathString;
  requiredKeys.forEach((key) => {
    const regex = new RegExp(`\\$\\{${key}\\}`, 'g');
    resultPath = resultPath.replace(regex, replacements[key]);
  });

  return resultPath;
}
