export const MARPPLE = {
  id: 'MARPPLE',
};
export const SELLER = {
  id: 'SELLER',
};
export const DIGITAL = {
  id: 'DIGITAL',
};
export const FAST_MARPPLE = {
  id: 'FAST_MARPPLE',
};
export const types = [MARPPLE, SELLER, DIGITAL, FAST_MARPPLE];
