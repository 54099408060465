import {
  $addClass,
  $closest,
  $delegate,
  $find,
  $qs,
  $removeClass,
  $setAttr,
  $setHTML,
  $setText,
  $trigger,
  $val,
} from 'fxdom/es';
import {
  compact,
  delay,
  each,
  every,
  find,
  go,
  html,
  omit,
  pick,
  pipe,
  pluck,
  reject,
  sel,
  some,
  strMap,
  tap,
} from 'fxjs/es';
import tippy from 'tippy.js';
import { AdScriptCriteoMpF } from '../../../../../modules/AdScript/Criteo/Mp/F/Function/module/AdScriptCriteoMpF.js';
import { AuthLoginModalMuiF } from '../../../../../modules/Auth/LoginModal/F/Mui/module/AuthLoginModalMuiF.js';
import { BpOptionF } from '../../../../../modules/BpOption/F/Function/module/BpOptionF.js';
import { BpOptionS } from '../../../../../modules/BpOption/S/Function/module/BpOptionS.js';
import { removeCvBlueLineInMaker } from '../../../../../modules/Maker/F/cv_blue_line.js';
import { makeFakeMpMakerSize } from '../../../../../modules/Maker/F/fake_mp_maker.js';
import { getCvDesigns } from '../../../../../modules/Maker/F/Fcanvas/cv_object.js';
import {
  getBaseProductInMaker,
  getProductColorInMaker,
  getRealFcanvass,
} from '../../../../../modules/Maker/F/getSth.js';
import {
  changeProductColorByBps,
  removeAllTempLocalStorage,
  renderFacesByCurrentBpcfsOnlySizeInMakerA,
  setTempLocalStorage,
} from '../../../../../modules/Maker/F/mp_maker.js';
import {
  check_all_in_all_sizes3,
  isMakerDone,
  moveToFcanvasIncludingSwiper,
} from '../../../../../modules/Maker/F/overflow.js';
import { requiringBorder } from '../../../../../modules/Maker/F/Product/bpc_tmpl.js';
import { baseProductChangeInit, open_chooser } from '../../../../../modules/Maker/F/Product/product_fs.js';
import {
  mpMakerSelectFaceActiveTippy,
  close_select_faceA,
  mpMakerSelectFaceDeactiveTippy,
} from '../../../../../modules/Maker/F/select_faces.js';
import { go_sharing } from '../../../../../modules/Maker/F/share.js';
import { onbeforeunload_event_off } from '../../../../../modules/Maker/F/util.js';
import { MarppleCustomDetailConstantS } from '../../../../../modules/Marpple/CustomDetail/S/Constant/module/MarppleCustomDetailConstantS.js';
import { openEnterMarppleShop1 } from '../../../../../modules/Marpple/PopUp/F/enter_marpple_shop1.js';
import { MarppleShinhanConstantS } from '../../../../../modules/Marpple/Shinhan/S/Constant/module/MarppleShinhanConstantS.js';
import { resetUserCounts, setUpCSsPrices } from '../../../../../modules/MPay/Cart/F/fs.js';
import {
  bpNameByUpC,
  makeCartModalItemInfoWrapHtml,
  makeCartModalItemInfoWrapHtmlSelect,
  makeCartModalPreviewHtml,
  makeCartModalProductPriceContentHtml,
  makeCartModalSummaryContentHtml,
  makeCartTooltipHtml,
} from '../../../../../modules/MPay/Cart/S/tmpl.js';
import { MuiF } from '../../../../../modules/Mui/F/Function/module/MuiF.js';
import { shinhanPfCondition } from '../../../../../modules/NewMaker/Checker/F/Function/isImageQualityPass.js';
import { NewMakerCompositePreviewF } from '../../../../../modules/NewMaker/CompositePreview/F/Function/module/NewMakerCompositePreviewF.js';
import { errorHandlingEventWrapper } from '../../../../../modules/NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { NewMakerLegacyF } from '../../../../../modules/NewMaker/Legacy/F/Function/module/NewMakerLegacyF.js';
import { NewMakerSharedProductF } from '../../../../../modules/NewMaker/SharedProduct/F/Function/module/NewMakerSharedProductF.js';
import { OMPAuthF } from '../../../../../modules/OMP/Auth/F/Function/module/OMPAuthF.js';
import { OMPCoreAtomTmplS } from '../../../../../modules/OMP/Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPReviewF } from '../../../../../modules/OMP/Review/F/Function/module/OMPReviewF.js';
import { PriceS } from '../../../../../modules/Price/S/Function/module/PriceS.js';
import { soldOutSizeIds } from '../../../../../modules/ProductColor/S/fs.js';
import { ProductDetailTabsF } from '../../../../../modules/ProductDetailTabs/F/Function/module/ProductDetailTabsF.js';
import { defnReviewListEvent } from '../../../../../modules/Review/List/F/event.js';
import { makeReviewListInfi } from '../../../../../modules/Review/List/F/fs.js';
import { SVGEditorProductDetailF } from '../../../../../modules/SVGEditor/ProductDetail/F/Function/module/SVGEditorProductDetailF.js';
import { isLoginCheck } from '../../../../../modules/User/Login/F/fs.js';
import { frameOpenSignUpIn } from '../../../../../modules/User/Login/F/tab.js';
import { UserProductS } from '../../../../../modules/UserProduct/S/Function/module/UserProductS.js';
import { UtilS } from '../../../../../modules/Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../../../modules/Util/S/Function/util.js';
import { VectorEditorConstantS } from '../../../../../modules/VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { NewMakerUtilF } from '../../../../../modules/NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { renderProductColorInProductDetailMobile } from '../../../../../modules/Maker/F/maker_tmpl.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';
import { OMPCoreUtilF } from '../../../../../modules/OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPMakerEventMakerF } from '../../../../../modules/OMP/MakerEvent/Maker/F/Function/module/OMPMakerEventMakerF.js';
import { ConfigSentryF } from '../../../../../modules/Config/Sentry/F/Function/module/ConfigSentryF.js';
import { OMPDosuF } from '../../../../../modules/OMP/Dosu/F/Function/module/OMPDosuF.js';
import { bizOptionMenuEvents, bizOptionMenus } from '../../../../../modules/Maker/F/Marpplizer/biz.js';
import { getIsBizProduct } from '../../../../../modules/Maker/S/product.js';
import { clickHandlerForReviewSummaryCount } from '../../../../../modules/Maker/F/Marpplizer/fs.js';
import { BpDiscountSetS } from '../../../../../modules/BpDiscountSet/S/Function/module/BpDiscountSetS.js';
import { NewMakerPropertyBaseProductF } from '../../../../../modules/NewMaker/Property/BaseProduct/F/Function/module/NewMakerPropertyBaseProductF.js';
import { changeProductBpcA } from '../../../../../modules/Maker/F/Product/bpc_fs.js';
import { BpDiscountSetConstantS } from '../../../../../modules/BpDiscountSet/S/Constant/module/BpDiscountSetConstantS.js';
import { BpOptionConstantS } from '../../../../../modules/BpOption/S/Constant/module/BpOptionConstantS.js';
import { UtilObjS } from '../../../../../modules/Util/Object/S/Function/module/UtilObjS.js';
import { BpOptionUploadTypeS } from '../../../../../modules/BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { MakerDebugF } from '../../../../../modules/MakerDebug/F/Function/module/MakerDebugF.js';
import { MakerDebugConstantS } from '../../../../../modules/MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import axios from 'axios';
import { NewMakerCallConditionPopupF } from '../../../../../modules/NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { NewMakerConditionAgreeTextF } from '../../../../../modules/NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { NewMakerConditionTooltipMoF } from '../../../../../modules/NewMaker/ConditionTooltipMo/F/Function/module/NewMakerConditionTooltipMoF.js';
import { BpDiscountSetWowBpDiscountSetF } from '../../../../../modules/BpDiscountSet/WowBpDiscountSet/F/Function/module/BpDiscountSetWowBpDiscountSetF.js';
import { SVGEditorUtilF } from '../../../../../modules/SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { NewMakerPropertyBaseProductS } from '../../../../../modules/NewMaker/Property/BaseProduct/S/Function/module/NewMakerPropertyBaseProductS.js';
import { NewMakerProductF } from '../../../../../modules/NewMaker/Product/F/Function/module/NewMakerProductF.js';

async function prepareCartForAdd() {
  const product = getProductColorInMaker();
  const bp_option_groups_el = $qs('.bp_option_groups');
  const has_bp_option_groups = BpOptionS.getBaseProductsListBpOptionGroups(product._.base_product)?.length;
  if (has_bp_option_groups)
    product._.selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);

  if (!UtilF.isLegacyMobile()) {
    if (product.product_faces2?.value[0]?.designs?.length) {
      const result = await NewMakerCompositePreviewF.openCompositePreview(product);
      if (result?.frame_done === false) return;
    }
  }

  box.unset('maker->product_color->id');
  box.unset('maker->product_color->product_id');
  box.unset('maker->product_color->thumbnails');

  const { is_pure_biz_product } = getIsBizProduct(product);
  if (is_pure_biz_product) {
    bizOptionMenus.upsert.toBoxAtPf2();
  }

  if (BpOptionF.biz.get.currentBp.hasDosuRelatedOptions()) {
    const is_done_dosu = await go(
      box.sel('maker->product_color->product_faces2->value'),
      every(async (pf) => {
        const dosu_confirm = await OMPDosuF.dosu_confirm.isMoreThanLimitedDosu(pf.bpf_id, pf.designs);
        if (dosu_confirm === undefined) return true;
        if (dosu_confirm === true) {
          await NewMakerPropertyBaseProductF.linkToBpId.goTo(async () => {
            if (UtilF.isLegacyMobile()) {
              await _p.go(G.mp.maker.zoom.canvas_container_display_mode(), NewMakerLegacyF.swiperInit);
            }
          });

          return;
        }
        const fcanvas = getRealFcanvass().find((fcanvas) => {
          return fcanvas.bpf_id === pf.bpf_id;
        });
        await moveToFcanvasIncludingSwiper(fcanvas);
      }),
    );
    if (!is_done_dosu) return;
    // if (G.collabo_type !== '') {
    //   const invalid_pf = OMPDosuF.validation.get.forProduct();
    //   if (invalid_pf != null) {
    //     $.don_loader_end();
    //     const { face_name, bpf_id } = invalid_pf;
    //
    //     await $.alert(`${face_name}에 색상 문제가 있어 해결이 필요합니다.`);
    //
    //     await moveToFcanvasIncludingSwiper(getFcanvasByBpfId(bpf_id));
    //     return;
    //   }
    // } else {

    // }
  }

  removeCvBlueLineInMaker();

  if (!(await isMakerDone())) return;
  if (box.sel('maker->event')) {
    if (
      !(await OMPMakerEventMakerF.shinhanIsGoodCondition(
        box.sel('maker->event'),
        box.sel('maker->product_color'),
      ))
    )
      return;
  }
  box.set('maker->product_color->og_image_url', null);
  check_all_in_all_sizes3();
  return true;
}
!(function (lo) {
  function redirectLogin(url, path = 'login') {
    setTempLocalStorage();
    const redirect_url = new URL(url);
    redirect_url.searchParams.set('is_redirect_from_login', 'true');
    const login_url = new URL(`${location.origin}/${T.lang}/${path}`);
    login_url.searchParams.set('url', redirect_url.pathname + redirect_url.search + redirect_url.hash);
    location.href = login_url.toString();
    $.don_loader_start();
    setTimeout(() => {
      $.don_loader_end();
    }, 5000);
  }

  async function requestLoginModal() {
    return new Promise((resolve) => {
      MuiF.openFrame(AuthLoginModalMuiF.frame, (frame, page, [tab]) => {
        frame.is_modal = !OMPCoreUtilF.isMobile();
        frame.animation = true;
        frame.hide_frame_button_position = 'RIGHT';
        frame.hide_frame_button_type = OMPCoreUtilF.isMobile() ? 'V' : 'X';
        frame.el_class = 'auth_login_modal no_header';
        frame.appended = $.frame_close_bg;
        frame.closed = () => {
          resolve('cancel');
        };
        frame.showed = (frame_el) => {
          go(
            frame_el,
            $delegate('click', '.auth_login_modal--footer > button', (e) => {
              const is_signup = !!e.target.closest('.auth_login_modal--signup');
              resolve(is_signup ? 'signup' : 'login');
            }),
          );
        };
        if (OMPCoreUtilF.isMobile()) {
          const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize ?? 16); // html의 font-size 값을 가져옴
          frame.height = 610 + 100 / rootFontSize + G.mp.frame.v_height;
          frame.header_height = G.mp.frame.v_height;
        }
      });
    });
  }

  function gtagFunc(up_c) {
    try {
      // gtag
      if (typeof gtag == 'function') {
        const quantity = _sum(up_c._.up_c_ss, function (up_c_s) {
          return up_c_s.quantity;
        });

        gtag('event', 'add_to_cart', {
          dynx_itemid: [box.sel('maker->product_color->_->base_product->id')], // 장바구니 담은 상품의 아이디 배열
          dynx_itemid2: [box.sel('maker->product_color->_->base_product->id')], // 장바구니 담은 상품의 아이디 배열
          dynx_pagetype: 'conversionintent',
          dynx_totalvalue: PriceS.mult(quantity, box.sel('maker->product_color->price' + _en)), // 장바구니 담은 상품 총 금액
          ecomm_prodid: [box.sel('maker->product_color->_->base_product->id')],
          ecomm_pagetype: 'conversionintent',
          event_label: 'conversionintent',
          items: [
            {
              id: box.sel('maker->product_color->_->base_product->id'),
              name: box.sel('maker->product_color->_->base_product->name'),
              list_name: '장바구니',
              item_category: sel('_.product_color._.base_product._.cate_list.name', up_c),
              item_category2: sel('_.product_color._.base_product._.cate_item.name', up_c),
              // "brand": "Google",
              // "category": "Apparel/T-Shirts",
              // "variant": "Black",
              list_position: 1,
              quantity: quantity,
              price: box.sel('maker->product_color->price' + _en),
            },
          ],
        });
      }
      // end gtag

      // fbq
      if (typeof fbq == 'function') {
        fbq('track', 'AddToCart', {
          value: _sum(_p.v(up_c, '_.up_c_ss'), function (up_c_s) {
            return PriceS.mult(up_c_s.quantity, up_c_s['price' + _en]);
          }),
          currency: T.lang == 'kr' ? 'KRW' : 'USD',
          // content_ids: [box.sel('maker->product_color->_->base_product->id')],
          content_type: 'product',
          contents: [
            {
              id: box.sel('maker->product_color->_->base_product->id'),
              name: box.sel('maker->product_color->_->base_product->name'),
              price: box.sel('maker->product_color->price' + _en),
              quantity: _sum(up_c._.up_c_ss, function (up_c_s) {
                return up_c_s.quantity;
              }),
            },
          ],
        });
      }
      // end fbq

      if (T.lang == 'kr' && typeof kakaoPixel == 'function') {
        kakaoPixel('2648073228351915297').viewContent({
          id: box.sel('maker->product_color->_->base_product->id'),
        });
      }
    } catch (err) {}
  }
  G.mp.product = {};
  G.mp.product.detail = G.mp.product.detail || {};
  lo.update_summary = function () {
    return _p.go(
      'maker->up_c->_->up_c_ss',
      box.sel,
      makeCartModalSummaryContentHtml,
      $.html_to('.modal .summary'),
    );
  };
  lo.wrap_up_by_type = function (type) {
    return async function () {
      const { svg_product_materials, selected_option_group } =
        await SVGEditorProductDetailF.createSVGMaterialForPC(
          pick(['svg_product_materials', 'selected_option_group'])(box.sel('maker->product_color->_')),
        );
      if (
        window.location.href.includes('localhost') &&
        VectorEditorConstantS.EDITOR_TYPES.includes(
          box.sel('maker->product_color->_->base_product->maker_type'),
        )
      ) {
        const base_products_bp_option_groups = box.sel(
          'maker->product_color->_->base_product->_->base_products_bp_option_groups',
        );
        if (
          VectorEditorConstantS.KEYRING_EDITOR == box.sel('maker->product_color->_->base_product->maker_type')
        )
          BpOptionS.keyringChecker({
            svg_product_materials,
            selected_option_group,
            svg_product_id: box.sel('maker->product_color->svg_product_id'),
            base_products_bp_option_groups,
          });
        else {
          if (go(base_products_bp_option_groups, pluck('default_bp_option_id'), (arr) => arr.includes(349))) {
            BpOptionS.halfStickerChecker({
              svg_product_materials,
              selected_option_group,
              svg_product_id: box.sel('maker->product_color->svg_product_id'),
            });
          } else if (
            go(
              box.sel('maker->product_color->_->base_product->_->base_products_bp_option_groups'),
              pluck('default_bp_option_id'),
              (arr) => arr.includes(112),
            )
          ) {
            BpOptionS.stickerChecker({
              svg_product_materials,
              selected_option_group,
              svg_product_id: box.sel('maker->product_color->svg_product_id'),
            });
          }
        }
      }
      setUpCSsPrices(box.sel('maker->up_c->_->up_c_ss'), box.sel('maker->product_color'));

      const product = box.sel('maker->product_color');
      await NewMakerProductF.postProcess.tattoo(product);
      return {
        product_color: _p.omit(box.sel('maker->product_color'), '_'),
        up_c: _p.extend({ type: type }, box.sel('maker->up_c')),
        svg_product_materials,
        selected_option_group,
      };
    };
  };
  lo.count_action = function (fn) {
    return __(
      _p.v('currentTarget'),
      $.closest('.item'),
      _p.all($.find1('.quantity'), __(box.sel, fn)),
      $.val,
      $.trigger('input'),
    );
  };

  $.frame.defn_frame({
    frame_name: 'mp.product.detail.modal',
    page_name: 'mp.product.detail.modal.page',
    loader: false,
    always_remove: true,
    appending: __(
      $.on('click', function (e) {
        if (e.target == e.delegateTarget) $.frame.close();
      }),
    ),
  });

  $.frame.defn_frame({
    frame_name: 'mp.product.share.modal',
    page_name: 'mp.product.share.modal.page',
    loader: false,
    always_remove: true,
    appending: __(
      $.on('click', function (e) {
        if (e.target == e.delegateTarget) $.frame.close();
      }),
    ),
  });

  $.frame.defn_page([
    {
      page_name: 'mp.product.share.modal.page',
      hide_frame_button_type: 'X',
      tabs: [
        {
          tab_name: 'mp.product.share.modal.tab',
          template: () => {
            return html`<div class="share_product_modal_wrap">
              <div class="share_product_modal">
                <div class="share_product_modal__title">상품 링크 생성하기</div>
              </div>
              <div class="share_product_modal_input">
                <div class="share_product_modal_input__title">
                  상품 링크 제목
                  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                    <circle cx="2" cy="2" r="2" fill="#FF6B00" />
                  </svg>
                </div>

                <div class="share_product_modal_input__wrap">
                  <input type="text" placeholder="상품 링크 제목을 입력해 주세요." />
                </div>
              </div>
              <div class="share_product_modal_buttons">
                <button class="cancel">취소</button>
                <button class="create">생성하기</button>
              </div>
            </div>`;
          },

          appended: _p.pipe(
            $delegate('input', 'input[type=text]', ({ currentTarget }) => {
              const create_button = go(
                currentTarget,
                $closest('.share_product_modal_wrap'),
                $find('.create'),
              );
              $val(currentTarget).length > 0
                ? $addClass('active', create_button)
                : $removeClass('active', create_button);
            }),
            $.on('click', '.share_product_modal_buttons .cancel', () => {
              $.frame.close();
            }),
            $.on('click', '.share_product_modal_buttons .create', async ({ currentTarget }) => {
              try {
                $.don_loader_start();
                const title = go(
                  currentTarget,
                  $closest('.share_product_modal_wrap'),
                  $find('input'),
                  $val,
                ).trim();
                const product_color = box.sel('maker->product_color');
                const { svg_product_materials, selected_option_group } =
                  await SVGEditorProductDetailF.createSVGMaterialForPC(
                    pick(['svg_product_materials', 'selected_option_group'])(
                      box.sel('maker->product_color->_'),
                    ),
                  );

                const { data } = await axios.post('/@api/shared/products', {
                  product_color,
                  user_id: box.sel('is_user->id'),
                  title: title.replace(/ /g, '_'),
                  svg_product_materials,
                  selected_option_group,
                });
                $.don_loader_end();
                $.frame.add_page({
                  page_name: 'shared_product_detail_page',
                  loader: true,
                  hide_frame_button_type: 'X',
                  tabs: [
                    {
                      tab_name: 'shared_product_detail_page_tab',
                      data_func: () => data,
                    },
                  ],
                });
              } catch (e) {
                $.alert(e.response.data);
                $.don_loader_end();
              }
            }),
          ),
        },
      ],
    },
    {
      page_name: 'shared_product_detail_page',
      hide_frame_button_type: 'x',
      tabs: [
        {
          tab_name: 'shared_product_detail_page_tab',
          template: (data) => {
            const details = [
              {
                title: '상품 링크 제목',
                value: data.shared_product.title,
              },
              {
                title: 'URL',
                value: `${window.location.origin}/${TT.lang}${data.url}`,
              },
              {
                title: '비밀번호',
                value: data.password,
              },
              {
                title: '유효기간',
                value: moment(data.shared_product.expires_at).format('YYYY/MM/DD HH:mm'),
              },
            ];

            return `<div class="share_product_modal_wrap">
            <div class="share_product_modal">
              <div class="share_product_modal__title">상품 링크가 생성되었어요.</div>
              <p>상품 링크 편집은 DF에서 진행해 주세요.</p>
              <p class="go_to_DF">바로가기 <img src="//s3.marpple.co/files/u_2798351/2024/7/original/60383470969f3364b16ae8de7ed44c1de601c7351.svg"/></a>
            </div>
            
            <div class="share_product_modal_input">
              ${strMap(
                (detail) => html`
                  <div class="share_product_modal_input__title">${detail.title}</div>
                  <div class="share_product_modal_input__wrap input_detail">
                    <input type="text" value="${detail.value}" readonly />
                    ${detail.title === 'URL' || detail.title === '비밀번호'
                      ? `<img class="clipboard" src="//s3.marpple.co/files/u_2798351/2024/7/original/17cb5cbf8865f4b592e6f5ff0960bed99db43d201.svg"/>`
                      : ''}
                  </div>
                `,
                details,
              )}
            </div>
            
            <div class="share_product_modal_buttons">
              <button class="done active">확인</button>
            </div>
          </div>`;
          },

          appended: _p.pipe(
            $delegate('click', '.go_to_DF', () => {
              window.open('https://df.marpple.com/omp_shared_products?keyword=&order=desc&sort=created_at');
            }),
            $delegate('click', '.done', () => {
              $.frame.close();
            }),
            $delegate('click', '.clipboard', ({ currentTarget }) => {
              const text = go(
                currentTarget,
                $closest('.share_product_modal_input__wrap'),
                $find('input'),
                $val,
              );
              navigator.clipboard.writeText(text).then(() => {
                $.alert('복사되었습니다.');
              });
            }),
          ),
        },
      ],
    },
  ]);

  function get_product_title(product_color) {
    return bpNameByUpC({ _: { product_color } });
  }

  function plusMinusEventFunc(val, is_plus) {
    const bp_discount_ranges = box.sel(
      'maker->product_color->_->base_product->_->bp_discount_set->_->bp_discount_ranges',
    );
    const pf2 = box.sel('maker->product_color->product_faces2');
    const is_biz = UtilObjS.isNotEmpty(pf2?.biz);
    if (bp_discount_ranges && is_biz) {
      if (box.sel('maker->product_color->_->base_product->_->base_product_sizes')?.length > 1) return val;
      const { quantity } = BpDiscountSetS.makePossibleQuantity(
        { quantity: val, is_plus },
        bp_discount_ranges,
      );
      const moq = pf2.biz[BpOptionConstantS.BIZ_PF2_DATA_KEYS.MOQ];
      return Math.max(moq, quantity);
      // 비즈 상품 moq 검사
    } else {
      return val;
    }
  }

  $.frame.defn_page({
    page_name: 'mp.product.detail.modal.page',
    hide_frame_button_type: 'X',
    tabs: [
      {
        tab_name: 'mp.cart.modal.tab',
        inner_scroll_target: '.list_body',
        data_func: async function () {
          const bpss = box.sel('maker->product_color->_->base_product->_->base_product_sizes');
          const product_color = box.sel('maker->product_color');
          const wow_qty = await go(undefined, () => {
            if (product_color._.base_product._?.bp_discount_set?.type !== BpDiscountSetConstantS.TYPE.MP_WOW)
              return;
            /* 상품 수량 모달 띄울때 */
            return BpDiscountSetWowBpDiscountSetF.service.getMinMaxQuantities({
              bp_id: product_color.base_product_id,
              bp_option_ids: product_color._.selected_option_group.bp_option_ids,
            });
          });
          const selected_option_size_value = BpOptionS.selectedOptionGroup.getSizeValue(
            product_color._.selected_option_group,
            G._en,
          );
          return [
            _p.go(
              box.sel('maker->up_c->_->up_c_ss'),
              _p.map(function (up_c_s, i) {
                const bps = _p.find(bpss, function (bps) {
                  return bps.id === up_c_s.base_product_size_id;
                });
                return _p.extend(
                  {
                    idx: i,
                    no: bps.no,
                    size: selected_option_size_value || bps['name' + _en],
                    price: PriceS.price(up_c_s.price),
                    price_en: PriceS.price(up_c_s.price_en),
                    discounted_price: PriceS.price(up_c_s.discounted_price),
                    discounted_price_en: PriceS.price(up_c_s.discounted_price_en),
                  },
                  up_c_s,
                );
              }),
              _p(setUpCSsPrices, _p, product_color),
              _p.sort_by('no'),
            ),
            product_color,
            wow_qty,
          ];
        },
        template: ([up_c_ss, product_color, wow_qty]) => {
          const {
            product_faces2,
            _: { base_product, base_product_color, selected_option_group },
          } = product_color;

          const selected_option_names = selected_option_group?.option_names;
          const has_selected_option_names = !UtilS.isEmpty(selected_option_names);
          const selected_option_names_el = has_selected_option_names
            ? `<div>${UserProductS.naming.makeOptionNames1({
                selected_option_group,
                base_product_size: {},
                base_product_color,
                base_product,
                product_faces2,
              })}</div>`
            : '';

          if (has_selected_option_names) {
            UserProductS.naming.makeEmptyUpSize(up_c_ss);
          }

          return legacyHtml`
            <div class="modal" _sel="maker->up_c">
              ${makeCartModalPreviewHtml(product_color, 'maker->product_color')}
              <div class="for_desktop">
                <div class="list_body" _sel="./_->up_c_ss">
                  <div class="product_title">${UserProductS.naming.makeBpNameWithColor({
                    base_product,
                    base_product_color,
                  })}</div>
                  ${selected_option_names_el}
                  ${strMap(
                    (up_c_s) => legacyHtml`
                      <div class="item" _sel="./${up_c_s.idx}" _idx="${up_c_s.idx}">
                        ${
                          wow_qty?.quantities?.length
                            ? makeCartModalItemInfoWrapHtmlSelect(up_c_s, wow_qty)
                            : makeCartModalItemInfoWrapHtml(up_c_s, wow_qty)
                        }
                        <div class="product_price_wrap">${makeCartModalProductPriceContentHtml(up_c_s)}</div>
                      </div>
                    `,
                    up_c_ss,
                  )}
                </div>
                <div class="modal_footer">
                  <div class="summary">${makeCartModalSummaryContentHtml(up_c_ss)}</div>
                  <div class="buttons">
                    <button id="buy" type="button">${T('Buy Now')}</button>
                    <button id="add_to_cart" type="button">${T('Add to Cart')}</button>
                  </div>
                </div>
              </div>
            </div>
          `;
        },
        showed: function ($tab) {
          G.mp.cart.modal_showed($tab);
        },
        appended: __(
          $.on('click', '.product_face_list.bottom .preview_thumb', function (e) {
            const swiper = $1('.product_face_container.swiper-container').swiper;
            const idx = $.attr(e.currentTarget, '_idx');
            swiper.slideTo(idx);
          }),

          $.on(
            'input',
            '.item:not(.is_overflowed):not(.is_not_stock) .input_wrap input.quantity',
            function (e) {
              return _p.go(e, G.mp.cart.quantity_update, lo.update_summary);
            },
          ),

          $.on('change', '.mp-select-product-quantity select', BpOptionF.selectQuantityEvent),

          $.on(
            'focusout',
            '.item:not(.is_overflowed):not(.is_not_stock) .input_wrap input.quantity',
            function ({ currentTarget: ct }) {
              ct.value = plusMinusEventFunc(ct.value, true);
              $trigger('input', ct);
            },
          ),

          $.on(
            'click',
            '.item:not(.is_overflowed):not(.is_not_stock) .input_wrap .minus',
            lo.count_action(function (up_c_s) {
              const val = up_c_s.quantity > 0 ? (up_c_s.quantity -= 1) : up_c_s.quantity;

              return plusMinusEventFunc(val, false);
            }),
          ),

          $.on(
            'click',
            '.item:not(.is_overflowed):not(.is_not_stock) .input_wrap .plus',
            lo.count_action(function (up_c_s) {
              const val = up_c_s.quantity < 5000 ? (up_c_s.quantity += 1) : up_c_s.quantity;
              return plusMinusEventFunc(val, true);
            }),
          ),

          $.on2('click', 'button#buy', async function (e) {
            if (
              _p.sum(box.sel('maker->up_c->_->up_c_ss'), function (up_c_s) {
                return up_c_s.quantity;
              }) === 0
            )
              return $.alert(T('Please select one or more products.'));
            try {
              let not_logged_in_path = '';
              if (!isLoginCheck()) {
                const res = await requestLoginModal();
                if (res === 'cancel') {
                  return;
                } else {
                  not_logged_in_path = res;
                }
              }
              $.don_loader_start();
              await _p.go(void 0, lo.wrap_up_by_type('direct'), async function (data) {
                if (!data) return;
                const result = await $.post('/@api/projection/direct', data);
                if (result?.message) {
                  return $.alert(
                    result.message || T('maker_error_alert::This is a temporary error. please try again.'),
                  );
                }
                await _p.go(
                  result,
                  _p.tap(function (up) {
                    onbeforeunload_event_off();
                    $.frame.extend_state(
                      void 0,
                      null,
                      window.location.pathname +
                        '?bp_id=' +
                        up.base_product_id +
                        '&pc_id=' +
                        up.product_color_id,
                      'replace',
                    );
                    const url = `${location.origin}/${T.lang}/checkout?direct=true&up_id=${up.id}`;
                    if (!isLoginCheck()) {
                      redirectLogin(url, not_logged_in_path);
                    } else {
                      location.href = url;
                      removeAllTempLocalStorage();
                      setTimeout(() => {
                        $.don_loader_end();
                      }, 5000);
                    }
                  }),
                );
              });
            } catch (e) {
              $.don_loader_end();
              $.alert(T('maker_error_alert::This is a temporary error. please try again.'));
            }
          }),

          $.on2('click', 'button#add_to_cart', async function () {
            try {
              let not_logged_in_path = '';
              if (!isLoginCheck()) {
                const res = await requestLoginModal();

                if (res === 'cancel') {
                  return;
                } else {
                  not_logged_in_path = res;
                }
              }

              $.don_loader_start();
              if (
                _p.sum(box.sel('maker->up_c->_->up_c_ss'), function (up_c_s) {
                  return up_c_s.quantity;
                }) === 0
              )
                return $.alert(T('Please select one or more products.'));

              const pc = box.sel('maker->product_color');
              if (box.sel('is_user')) {
                const { data: is_exist_product } = await axios.post(
                  `/${T.lang}/@api/cart/exist`,
                  omit('_', pc),
                );
                /*이미 장바구니에 담긴건지 체크*/
                if (is_exist_product) return $.alert(T('This item is already in your cart'));
              }
              const props_for_add_cart = await lo.wrap_up_by_type('cart')();
              const { data: up_c } = await axios.post(`/${T.lang}/@api/ups/add`, props_for_add_cart);
              /*이미 장바구니에 담음*/
              if (!up_c) return $.alert(T('Failed to put in cart'));
              gtagFunc(up_c);
              const url = G.update_query_str(location.href, 'pc_id', up_c._.product_color.id);
              if (!isLoginCheck()) {
                redirectLogin(url, not_logged_in_path);
              } else {
                resetUserCounts();
                /*장바구니 담긴 갯수 업데이트*/
                box.set('maker->product_color->product_id', up_c._.product_color.product_id);
                /*장바구니 담긴 갯수 업데이트*/
                _each(box.sel('maker->up_c->_->up_c_ss'), function (up_c_s) {
                  up_c_s.quantity = 0;
                });
                await $.frame.close();
                $.frame.extend_state(void 0, null, url, 'replace');
                onbeforeunload_event_off();
                if (
                  collabo_type === '' &&
                  $1('html[lang="kr"]') &&
                  window.localStorage.getItem('did_enter_marpple_shop') !== 'true'
                )
                  openEnterMarppleShop1();

                _p.go($.scroll_top2(window, 0, 400), () =>
                  $.tooltip({
                    data: { ...up_c, store_url: box.sel('store_url') },
                    template: makeCartTooltipHtml,
                    target: NewMakerUtilF.findCartTarget(),
                    class: 'for_cart',
                    auto_hide: true,
                    width: !UtilF.isLegacyMobile() ? 380 : 327,
                    appended: function (tooltip) {
                      return G.mp.maker.draw_product_face2(
                        tooltip,
                        _p.v(box.sel(tooltip), '_.product_color'),
                      );
                    },
                  }),
                );
                removeAllTempLocalStorage();
              }
            } catch (e) {
              const axios_error = e?.response?.data;
              if (!axios_error?.no_sentry) {
                ConfigSentryF.makerCapture(e);
              }
              return $.alert(
                axios_error?.message || T('maker_error_alert::This is a temporary error. please try again.'),
              );
            } finally {
              $.don_loader_end();
            }
          }),

          function (el_parent) {
            _go(el_parent, $.find('.product_face_list'), G.mp.maker.draw_product_face6);
            G.mp.cart.overflow_size_modal_check(el_parent);
          },
        ),
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'mp.maker.fake.mobile',
    page_name: 'mp.maker.fake.mobile_page',
    // height: 0,
    prev_frame_show: true,
    closing: async () => {
      mpMakerSelectFaceDeactiveTippy();
      /* 모바일 만들기툴 프레임 종료 */
      makeFakeMpMakerSize();
      G.mp.maker.unselect_all();
      G.product_detail_scroll_pass = true;
      if ($1('html.all_faces_mode')) {
        await close_select_faceA();
      }
      if (UtilF.isLegacyMobile()) {
        if (NewMakerCallConditionPopupF.is_mapping_confirm_popup()) {
          if (!NewMakerConditionAgreeTextF.getAgreeTextData()?.length) {
            NewMakerCallConditionPopupF.resetForNotShowingWarning();
          }
        }
      }
      BpOptionF.makeSelectDisabled();
      renderProductColorInProductDetailMobile();
      baseProductChangeInit();
      G.mp.maker.remove_class_el_maker();
      go(G.mp.maker.zoom.canvas_container_display_mode(), NewMakerLegacyF.swiperInit, function () {
        $.remove_class($1('html'), 'maker_editor_mode_for_canvas');
      });
    },
    showed: function () {
      localStorage.setItem(`init_time`, new Date().getTime());
    },
    appended: () => {
      ProductDetailTabsF.scrollDestroy();
    },
    closed: async () => {
      if (typeof gtag == 'function') {
        const init_time = localStorage.getItem('init_time');
        if (init_time) {
          gtag('event', 'maker_dwell_time', {
            dwell_time: new Date().getTime() - init_time,
          });
        }
      }
      localStorage.removeItem('init_time');
      $.remove_class($1('html'), 'maker_editor_mode');
      $.don_loader_end();
      _p.go($('.canvas_man'), $.remove);
      _p.go($('.tip_box'), $.remove);
      await G.mp.maker.maker_mobile_close();
      $.css($('#maker_frame'), {
        height: G.mp.maker.canvas_container_height,
      });
      await anime({
        targets: compact([
          $1('#footer'),
          G.collabo_type === '' ? NewMakerUtilF.getMobileHeader() : $1('#header'),
          $1('.detail_wrapper'),
          $1('.fake_mp_maker'),
          $1('.footer_buttons'),
        ]),
        duration: 500,
        opacity: 1,
      }).finished;
      G.product_detail_scroll_pass = false;
      const tab_el = $qs('.don_tab[tab_name="mp.product.detail"]');
      if (tab_el) await ProductDetailTabsF.init(tab_el, true);
    },
  });
  $.frame.defn_page({
    page_name: 'mp.maker.fake.mobile_page',
    tabs: [
      {
        tab_name: 'mp.maker.fake.mobile_tab',
        template: () => html` <div></div> `,
      },
    ],
  });

  // todo @ddevkim easter egg 삭제
  const { easter_egg, easter_egg_evt_handler } = UtilF.getEasterEgg({
    activation_msg: 'PDF download is now activated!',
  });

  async function isNextStepAllowed(e) {
    if (NewMakerCallConditionPopupF.is_mapping_confirm_popup()) {
      const is_disabled = e.target.classList.contains('btn_disabled');
      if (is_disabled) {
        G.mp.maker.unselect_all();
        return;
      }
    }
    if (G.mp.maker.conditionCheckerInstance) {
      if (!(await G.mp.maker.conditionCheckerInstance.openConfirmModalMobile())) return;
    }
    const MAKER_TYPE = box.sel('maker->product_color->_->base_product->maker_type');
    const is_user = box.sel('is_user');
    await G.mp.maker.cleared();
    const product_color = getProductColorInMaker();
    let loaderDone = null;
    if (
      BpOptionUploadTypeS.UPLOAD_TYPE.isEditorType(product_color) &&
      NewMakerPropertyBaseProductS.pdfFilePossible.isIt(product_color._.base_product)
    ) {
      loaderDone = SVGEditorUtilF.percentLoader({
        message: T('modules::VectorEditor::AcrylicFigure::message::make_images'),
        time: 8 * 1000,
        clazz: UtilF.isLegacyMobile() ? 'mobile' : 'pc',
      });
    } else {
      $.don_loader_start();
    }
    try {
      if (!(await prepareCartForAdd())) {
        $.don_loader_end();
        if (loaderDone) await loaderDone().catch(() => {});
        return false;
      }
    } catch (e) {
      if (loaderDone) await loaderDone().catch(() => {});
      MakerDebugF.api.MakerDebugPost(
        getProductColorInMaker(),
        e,
        MakerDebugConstantS.Category.PostMaker,
        'to_option',
      );
      if (
        G.collabo_type === '' &&
        e instanceof TypeError &&
        ['DEFAULT', 'MASKING_TAPE'].includes(MAKER_TYPE) &&
        is_user
      ) {
        $.don_loader_end();
        await $.alert(`${T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.')}[ERROR::02]`);

        onbeforeunload_event_off();
        $.don_loader_start();
        window.location.reload();
        return false;
      } else {
        ConfigSentryF.makerCapture(e);
        $.don_loader_end();
        await $.alert(`${T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.')}[ERROR::01]`);
        return false;
      }
    }
    /* 장바구니 동작 시작 */
    $.don_loader_end();
    if (loaderDone) await loaderDone().catch(() => {});
    return true;
  }

  $.frame.defn_frame({
    frame_name: 'mp.product.detail',
    page_name: 'mp.product.detail',
    appended: __(
      _p.tap(function () {
        // $.don_loader_start();
        if (G.mp.maker.isInstagramApp || G.mp.maker.isFacebookApp) {
          let window_height = $.height(window);
          let window_width = $.width(window);
          var interval_ = setInterval(function () {
            if (window_height == $.height(window) && window_width == $.width(window)) {
              G.mp.maker.start1();
              clearInterval(interval_);
              return;
            }
            window_height = $.height(window);
            window_width = $.width(window);
          }, 5000);
        } else {
          G.mp.maker.start1();
        }
      }),
      _p.tap(() => {
        if (G.collabo_type == '')
          AdScriptCriteoMpF.viewItem({ id: box.sel('maker->product_color->base_product_id') });
      }),
    ),
  });

  function style_size(text, property, style) {
    return go(
      legacyHtml` <div style="visibility:hidden;" class="wow">${text}</div> `,
      $.append_to($1('body')),
      $.css(style),
      (el) =>
        go(
          property === 'outer_height_margin' ? $.outerHeight(el, true) : $[property](el),
          tap(() => $.remove(el)),
        ),
    );
  }

  $.frame.defn_page({
    page_name: 'mp.product.detail',
    tabs: [
      {
        tab_name: 'mp.product.detail',
        infinite: G.collabo_type == '' ? OMPReviewF.reviewInfi : makeReviewListInfi(),
        appended: (tab_el) => {
          NewMakerPropertyBaseProductF.pdfFilePossible.activeTippy();
          return go(
            tab_el,
            tap(pipe($delegate('click', '.review_summary .counts', clickHandlerForReviewSummaryCount))),
            tap(BpOptionF.event),
            tap(SVGEditorProductDetailF.vectorEditorOptionsEvent),
            tap(ProductDetailTabsF.event),
            tap(ProductDetailTabsF.init),
            tap(OMPReviewF.event),
            // 순수 비즈 상품일 때 비즈 관련 옵션 이벤트 설정
            tap((tab_el) => {
              const product_color = box().maker.product_color;
              const { is_pure_biz_product } = getIsBizProduct(product_color);
              if (is_pure_biz_product) {
                return bizOptionMenuEvents(tab_el);
              }
            }),
          );
        },
      },
    ],
    appended: __(
      $.on2('click', '#to_direct_custom', async function () {
        return;
        /*나중에 다시 봐야합니다.*/
        box.unset('maker->product_color->id');
        box.unset('maker->product_color->product_id');

        const is_sold_out_size = go(
          box().maker.product_color._.base_product._.base_product_colors,
          find((bpc) => bpc.id === box().maker.product_color.base_product_color_id),
          (bpc) => {
            if (!bpc) return;
            return go(
              bpc._.sold_out_sizes,
              find((sos) => sos.id === box().maker.product_color.base_product_size_id),
            );
          },
        );
        if (is_sold_out_size) {
          return $.alert('품절된 사이즈입니다.');
        }
        const maker_frame_el = $qs('#maker_frame');
        const bp_option_groups_el = $qs('.bp_option_groups');
        if (maker_frame_el?.dataset?.is_vector === 'true') {
          if (bp_option_groups_el?.dataset?.is_designed !== 'true') {
            $.alert(T('maker_error_alert::앗! 제품을 만들지 않으셨어요.'));
            return;
          }
          const product = getProductColorInMaker();
          product._.selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);
        }
        $.don_loader_start();
        removeCvBlueLineInMaker();
        if (UtilF.isLegacyMobile()) {
          const time_per_1000_px = 100;
          const duration = (Math.abs($.scroll_top(window) - 0) / 1000) * time_per_1000_px;
          await anime({
            targets: 'html, body',
            scrollTop: 0,
            duration,
            easing: 'easeInOutCubic',
          }).finished;
        }
        return _p.go(
          undefined,
          _p.wait(50),
          () => isMakerDone(),
          async function (bool) {
            if (!bool) return;
            box.set('maker->product_color->og_image_url', null);
            check_all_in_all_sizes3();
            G.mp.maker.up_c_setting();
            return _p.go(
              void 0,
              lo.wrap_up_by_type('direct'),
              async function (data) {
                const current_page_event_info = go(
                  MarppleCustomDetailConstantS.eventList,
                  find((info) => location.pathname.includes(info.url)),
                );
                if (current_page_event_info) {
                  data.product_color.product_faces2.ev = { ...current_page_event_info };
                  if (
                    data.product_color.product_faces2.ev.don_frame_key ===
                    MarppleShinhanConstantS.info.don_frame_key
                  ) {
                    const has_shinhan_required_image = go(
                      getRealFcanvass(),
                      some((fcanvas) => {
                        return G.mp.maker.cv_objects_deep_find(getCvDesigns(fcanvas._objects), (cv_obj) => {
                          if (cv_obj._data?.cv_image_attrs?._data) {
                            return cv_obj._data?.cv_image_attrs?._data?.name?.includes('sh_x');
                          }
                          return cv_obj._data?.name?.includes('sh_x');
                        });
                      }),
                    );
                    if (!has_shinhan_required_image) {
                      $.don_loader_end();
                      await $.alert(`신한프렌즈 캐릭터 이미지(필수 에셋)와<br />함께 디자인 해주세요.`);
                      return;
                    }
                    const result = await shinhanPfCondition({
                      product_faces2: box().maker.product_color.product_faces2,
                      base_product_size_id: box().maker.product_color.base_product_size_id,
                    });
                    if (result?.message) {
                      $.don_loader_end();
                      $.alert(result?.message);
                      return;
                    }
                  }
                } else {
                  if (data.product_color.product_faces2.ev) {
                    $.don_loader_end();
                    $.alert('Error');
                    return;
                  }
                }

                if (isLoginCheck()) return data;
                return _p.go(frameOpenSignUpIn(), function (res) {
                  if (res && res.is_session) return data;
                });
              },
              async function (data) {
                if (!data) return;

                const res = await axios.get('/@api/maker_event/event', {
                  params: {
                    event_name: MarppleShinhanConstantS.info.event_name,
                  },
                });

                $.don_loader_end();

                if (!res.data?.maker_event) return $.alert('이벤트 참여 기간이 아닙니다.');

                $.don_loader_start();
                return (
                  _p.go(
                    data,
                    _p($.post, '/@api/projection/direct'),
                    _p.tap(function (up) {
                      onbeforeunload_event_off();
                      $.frame.extend_state(
                        void 0,
                        null,
                        window.location.pathname +
                          '?bp_id=' +
                          up.base_product_id +
                          '&pc_id=' +
                          up.product_color_id,
                        'replace',
                      );
                    }),
                    _p.redirect('/checkout?direct=true&up_id={{$.up_id}}'),
                  ),
                  _p.catch(function (e) {
                    $.don_loader_end();
                    throw e;
                  })
                );
              },
            );
          },
          () => {
            $.don_loader_end();
          },
        );
      }),
      $.on2('click', '.omp-atom__heart-count-button', async function (e) {
        const user_id = box.sel('is_user->id');
        if (!user_id) return OMPAuthF.openLogin();
        try {
          const base_product = box.sel('maker->product_color->_->base_product');
          const ct = e.currentTarget;
          const active = !base_product._.base_products_like?.user_id;
          await axios.post(`/${TT.lang}/@api/base_product/like`, {
            base_product_id: base_product.id,
            active,
          });
          if (active) {
            base_product.like_count++;
            base_product._.base_products_like = {
              user_id,
              base_product_id: base_product.id,
            };
          } else {
            base_product.like_count--;
            base_product._.base_products_like = {};
          }
          ct.outerHTML = OMPCoreAtomTmplS.heartCountButton({
            active,
            badge: base_product.like_count,
          });
          await OMPCoreUtilF.resetCartWish();
        } catch (e) {
          $.alert('문제가 발생했습니다. 다시 시도해주세요.');
        }
      }),
      $.on2('click', '#to_option', async function (e) {
        if (!(await isNextStepAllowed(e))) return;
        const product_color = box.sel('maker->product_color');
        G.mp.maker.up_c_setting();
        const biz = product_color.product_faces2.biz;
        if (biz?.quantity) {
          go(
            box.sel('maker->up_c->_->up_c_ss'),
            reject((up_c_s) => up_c_s.base_product_size_id === product_color.base_product_size_id),
            each((up_c_s) => (up_c_s.quantity = 0)),
          );
          go(
            box.sel('maker->up_c->_->up_c_ss'),
            find((up_c_s) => up_c_s.base_product_size_id === product_color.base_product_size_id),
            (up_c_s) => (up_c_s.quantity = biz.quantity),
          );
        }

        $.don_loader_end();
        if (UtilF.isLegacyMobile()) {
          const time_per_1000_px = 100;
          const duration = (Math.abs($.scroll_top(window) - 0) / 1000) * time_per_1000_px;
          await anime({
            targets: 'html, body',
            scrollTop: 0,
            duration,
            easing: 'easeInOutCubic',
          }).finished;
          const header_height = 65;
          const product_title = get_product_title(product_color);
          const title_height = style_size(product_title, 'outer_height_margin', {
            padding: '0 24px 16px',
          });
          const footer_height = 106;
          const plus_other_height = _en === '_en' ? 20 : 0;
          const other_height = header_height + title_height + footer_height + plus_other_height;
          const item_height = 85;
          const size_length = box.sel('maker->up_c->_->up_c_ss').length;
          const total_height = size_length * item_height + other_height;
          if (total_height < $.height(window) - 100) {
            return $.frame.open({
              frame_name: 'mp.product.detail.modal',
              animation: true,
              is_modal: !UtilF.isLegacyMobile(),
              el_class: 'product_detail_modal has_up_modal_style not_many_sizes',
              prev_frame_show: UtilF.isLegacyMobile(),
              height: total_height + 10,
              appended: $.frame_close_bg,
              showed: $.on_prevent_default,
              hiding: $.off_prevent_default,
            });
          }
        }
        return $.frame.open({
          frame_name: 'mp.product.detail.modal',
          is_modal: !UtilF.isLegacyMobile(),
          el_class: 'product_detail_modal has_up_modal_style shrink-modal',
        });
        /* 장바구니 동작 끝 */
      }),
      $.on('click', '.make_share_product', async (e) => {
        const maker_frame_el = $qs('#maker_frame');
        const bp_option_groups_el = $qs('.bp_option_groups');
        if (maker_frame_el?.dataset?.is_vector === 'true') {
          if (bp_option_groups_el?.dataset?.is_designed !== 'true') {
            $.alert(T('maker_error_alert::앗! 제품을 만들지 않으셨어요.'));
            return;
          }
        }

        if (!(await isNextStepAllowed(e))) return;

        return $.frame.open({
          frame_name: 'mp.product.share.modal',
          is_modal: !UtilF.isLegacyMobile(),
        });
      }),
      $.on('click', '.make_share_product_save', async (e) => {
        try {
          if (!(await isNextStepAllowed(e))) return;
          $.don_loader_start();
          const product_color = box.sel('maker->product_color');
          const { shared_product_id } = box.sel('shared_product');

          const { svg_product_materials, selected_option_group } =
            await SVGEditorProductDetailF.createSVGMaterialForPC(
              pick(['svg_product_materials', 'selected_option_group'])(box.sel('maker->product_color->_')),
            );

          const { data } = await axios.post('/@api/shared/products_update', {
            product_color,
            shared_product_id,
            user_id: box.sel('is_user->id'),
            svg_product_materials,
            selected_option_group,
          });
          if (UtilF.isLegacyMobile()) {
            const footer = $qs('.footer_buttons');
            footer.__tippy = tippy(footer, {
              content: `<div style="display: flex;align-items: center;"><span>변경사항이 저장되었습니다.</span></div>`,
              arrow: false,
              allowHTML: true,
              theme: 'maker-save_shared_product-mobile',
              showOnCreate: true,
              placement: 'top',
              appendTo: footer,
              duration: [300, 300],
              hideOnClick: false,
              trigger: 'menual',
              onShown(instance) {
                setTimeout(() => {
                  instance.hide();
                }, 2000);
              },
            });
          } else {
            go(e.currentTarget, $setAttr({ is_saved: 'true' }));
            go(
              e.currentTarget,
              $setHTML(`
            <img src="//s3.marpple.co/files/u_2798351/2024/7/original/cf096f14d7f98e7959dad3b1cc4d07aff0f2f0e31.svg"> 저장되었습니다.
          `),
            );
          }

          await delay(2000)();

          if ($qs('#maker_frame').dataset?.is_vector === 'true') {
            const url = G.update_query_str(location.href, 'pc_id', data.product_id);
            onbeforeunload_event_off();
            window.location.replace(url);
          } else {
            if (UtilF.isLegacyMobile()) {
              go(e.currentTarget, $setAttr({ can_save: 'false' }));
            } else {
              go(e.currentTarget, $setText('변경사항 저장하기'), $setAttr({ can_save: 'false' }));
              $setAttr({ is_saved: 'false' }, e.currentTarget);
            }

            box.sel('shared_product')._original_state_index = G.mp.maker.state.getCurrentStateIndex();
            NewMakerSharedProductF.renderSharedProductCanSaveButton(false);
          }

          $.don_loader_end();
          removeAllTempLocalStorage();
        } catch (e) {
          $.don_loader_end();
          $.alert(e);
        }
      }),
      defnReviewListEvent,
      $.on2('click', '.detail_wrapper .open_chooser', open_chooser),
      $.on2('click', '.detail_wrapper .share_j', go_sharing),
      $.on(
        'click',
        '.detail_wrapper .color_list .selected .item',
        errorHandlingEventWrapper(function (e) {
          const item_color = _go($1('.product_color .item.color'));

          $.frame.open(
            {
              frame_name: 'maker.picker.bpcs',
            },
            {
              selecting: __(
                _p.tap(function (bpc) {
                  _go(
                    item_color,
                    $.find1('.code'),
                    $.attr({
                      color_code: bpc.color_code,
                      need_ccc: requiringBorder([bpc.color_code, bpc.color_code2]),
                    }),
                    $.css({
                      background: bpc.color_code2
                        ? `linear-gradient(135deg, ${bpc.color_code} 50%, ${bpc.color_code2} 50%)`
                        : bpc.color_code,
                    }),
                  );
                  _go(item_color, $.find1('.name'), $.text(bpc['name' + _en]));
                  _p.go(
                    bpc,
                    _p.v('_.sold_out_sizes'),
                    _p.pluck('id'),
                    _p(_p.contains, _p, box.sel('maker->product_color->base_product_size_id')),
                    function (bool) {
                      const item_size = $1('.product_color .size_list > .selected > .item');
                      $.remove_class(item_size, 'sold_out');
                      bool ? $.add_class(item_size, 'sold_out') : $.remove_class(item_size, 'sold_out');
                    },
                  );
                }),
                // changeProductColorByBpc,
                async function (bpc) {
                  await changeProductBpcA(bpc);
                },
              ),
            },
          );
        }),
      ),

      $.on(
        'click',
        '.detail_wrapper .size_list .selected .item',
        errorHandlingEventWrapper(function () {
          const item_color = _go($1('.product_color .item.size'));
          $.frame.open(
            {
              frame_name: 'maker.picker.bpss',
            },
            {
              selecting: __(
                _p.tap(
                  errorHandlingEventWrapper(function (bps) {
                    const product_color = box.sel('maker->product_color');
                    _go(
                      soldOutSizeIds(product_color, product_color._.base_product._.base_product_colors),
                      _p(_p.contains, _p, bps.id),
                      function (is_sold_out) {
                        if (is_sold_out) return $.add_class(item_color, 'sold_out');
                        return $.remove_class(item_color, 'sold_out');
                      },
                      function () {
                        _go(item_color, $.find1('.name'), $.text(bps['name' + _en]));
                      },
                    );
                  }),
                ),
                errorHandlingEventWrapper(changeProductColorByBps),
                errorHandlingEventWrapper(renderFacesByCurrentBpcfsOnlySizeInMakerA),
                errorHandlingEventWrapper(renderProductColorInProductDetailMobile),
                // swiper_init,
              ),
            },
          );
        }),
      ),

      /* 면 좌우로 움직이기*/
      _p.tap(NewMakerLegacyF.makerMobileSwiper),
      $.on('click', '#to_maker', async function (e) {
        const time_per_1000_px = 100;
        const duration = (Math.abs($.scroll_top(window) - 0) / 1000) * time_per_1000_px;
        const upload_type_bp_option_group_el = BpOptionF.getBpOptionGroupEl(
          BpOptionUploadTypeS.UPLOAD_TYPE.bp_option_group_id,
        );
        const base_product = getBaseProductInMaker();
        if (upload_type_bp_option_group_el && base_product.maker_type === 'DEFAULT') {
          const is_go = await NewMakerPropertyBaseProductF.pdfFilePossible.goToEditorType(
            BpOptionUploadTypeS.UPLOAD_TYPE.option.editor.bp_option_id,
          );
          if (is_go === false) return;
          BpOptionF.selectBpOption({
            bp_option_group_el: upload_type_bp_option_group_el,
            selected_bp_option_id: BpOptionUploadTypeS.UPLOAD_TYPE.option.editor.bp_option_id,
          });
          const bp_option_groups_el = $qs('.bp_option_groups');
          const selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);
          const product_color = getProductColorInMaker();
          product_color._.selected_option_group = selected_option_group;
          if (!BpOptionUploadTypeS.UPLOAD_TYPE.option.editor.isReadyBp(getProductColorInMaker())) {
            return $.alert(
              '이 상품은 아직 만들기툴 준비중이에요,<br>템플릿을 다운받아 [디자인파일업로드]를 이용해 주세요.',
            );
          }
        }
        $.attr($1('html'), 'f_index', '1');
        try {
          await _p.go(
            anime({
              targets: 'html, body',
              scrollTop: 0,
              duration,
              easing: 'easeInOutCubic',
            }).finished,
            function () {
              return anime({
                targets: compact([
                  $1('#footer'),
                  G.collabo_type === '' ? NewMakerUtilF.getMobileHeader() : $1('#header'),
                  $1('.detail_wrapper'),
                  $1('.fake_mp_maker'),
                  $1('.footer_buttons'),
                ]),
                duration: 300,
                opacity: 0,
                easing: 'linear',
              }).finished;
            },
            function () {
              $.add_class($1('html'), 'maker_editor_mode');
              $.add_class($1('html'), 'maker_editor_mode_for_canvas');
              G.mp.maker.ban_frame_hide();
            },
            async function () {
              $.css($('#maker_frame'), { height: 'auto' });
              $.frame.open({
                frame_name: 'mp.maker.fake.mobile',
                animation: false,
              });
              /*session*/
              if (window.localStorage.getItem('done_tutorial') !== 'true') {
                if (box.sel('done_tutorial')) window.localStorage.setItem('done_tutorial', 'true');
                else G.mp.maker.tip_func_start();
              }
              await G.mp.maker.select_face_initA();
              if (G.mp.maker.conditionCheckerInstance) {
                G.mp.maker.conditionCheckerInstance.reset();
              }
              mpMakerSelectFaceActiveTippy();
            },
          );
        } catch (e) {
          $.alert(T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.'));
          ConfigSentryF.makerCapture(e);
        }
      }),
    ),
  });
})({});
