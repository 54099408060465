import { DeliveryTypeConstantS } from '../Constant/module/DeliveryTypeConstantS.js';

export const isMarpple = (delivery_type_id) => {
  return delivery_type_id == DeliveryTypeConstantS.MARPPLE.id;
};
export const isSeller = (delivery_type_id) => {
  return delivery_type_id == DeliveryTypeConstantS.SELLER.id;
};
export const isDigital = (delivery_type_id) => {
  return delivery_type_id == DeliveryTypeConstantS.DIGITAL.id;
};
/* @delivery_type_id FAST_MARPPLE 을 만들자 */
export const isFastMarpple = (delivery_type_id) => {
  return delivery_type_id == DeliveryTypeConstantS.FAST_MARPPLE.id;
};

export const isNotMarpple = (delivery_type_id) => {
  return isSeller(delivery_type_id) || isDigital(delivery_type_id);
};
